import React, { useState } from "react";
import {
  AsyncTypeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import MediaQuery from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faMapMarkerAlt,
  faCompass,
} from "@fortawesome/free-solid-svg-icons";
import { faLocation } from "@fortawesome/pro-solid-svg-icons";
import Sidebar from "./Sidebar";
import { isNil } from "lodash";
import "./LocationSearch.scss";
import LocationSearchInput from "./LocationSearchInput";

export default function LocationSearch({
  setLocation,
  setCurrentLocation,
  locationName,
  currentLocationName,
}) {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showInputBox, setShowInputBox] = useState(false);

  return (
    <React.Fragment>
      <MediaQuery minWidth={993}>
        <div className="container pl-6 mb-2 d-flex flex-column">
          <div
            className="d-flex flex-row makePointer"
            onClick={() => setShowInputBox(!showInputBox)}
          >
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              size="sm"
              className="m-0 text-primary makePointer"
            />

            <p className="location-name">{locationName}</p>
          </div>
          {showInputBox && (
            <LocationSearchInput
              setShowInputBox={setShowInputBox}
              setLocation={setLocation}
              setCurrentLocation={setCurrentLocation}
            />
          )}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={993}>
        <div className="container d-flex flex-row mt- 1mb-2 makePointer locationDisplayContainer">
          <div className="w-100 d-flex flex-column">
            <div className="w-100 h-50 border-bottom"></div>
            <div className="w-100 h-50"></div>
          </div>
          <div
            className="d-flex flex-row flex-shrink-0"
            onClick={() => setShowSideBar(true)}
          >
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              size="sm"
              className="ml-1 mt-0 mr-1 text-primary"
              onClick={() => setShowSideBar(true)}
            />
            <div className="location-name-wrapper">
              <p className="m-0 location-name">{locationName}</p>
            </div>
          </div>
          <div className="w-100 d-flex flex-column">
            <div className="w-100 h-50 border-bottom"></div>
            <div className="w-100 h-50"></div>
          </div>
        </div>
      </MediaQuery>
      <Sidebar
        title={"Set Current Location"}
        show={showSideBar}
        closeSidebar={() => setShowSideBar(false)}
      >
        <div className="w-100 d-flex flex-column">
          <div className="d-flex flex-row">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              size="lg"
              className="ml-1 mr-2 mt-2 text-primary"
            />

            <p className="text-muted pt-2 location-name">{locationName}</p>
          </div>
          <div>
            <LocationSearchInput
              setShowInputBox={setShowInputBox}
              setLocation={setLocation}
              setCurrentLocation={setCurrentLocation}
              setShowSideBar={setShowSideBar}
            />
          </div>
        </div>
      </Sidebar>
    </React.Fragment>
  );
}
