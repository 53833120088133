import React, { useState, useEffect } from "react";
import HomePageCoupons from "./HomePageCoupons";
import { fetchHomepageStoreLocations } from "../lib/api";

export default function HomePageStores({ latitude, longitude }) {
  const [store_locations, setStoreLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiCall({ latitude, longitude });
  }, [longitude, latitude]);

  const apiCall = async ({ latitude, longitude }) => {
    setLoading(true);
    const newStoreLocations = await fetchHomepageStoreLocations({
      latitude,
      longitude,
    });
    setStoreLocations(newStoreLocations);
    setLoading(false);
  };

  if (loading) return <div></div>;
  return (
    <div className="bg-grey pt-7">
      {store_locations.map((storeLocation) => (
        <div className="container" key={storeLocation.id}>
          <div className="d-none d-md-block">
            <h4 className="section-header d-flex align-items-center text-uppercase store-title">
              {storeLocation.name}{" "}
              <span className="store-distance bg-white rounded ml-4 rounded-lg store-title">
                {storeLocation.distance.toFixed(2)} mi
              </span>
              <a
                className="ml-auto text-muted view-all-link small"
                href={`/deals/${storeLocation.slug}`}
              >
                View All
              </a>
            </h4>
          </div>
          <div className="d-block d-md-none">
            <h4 className="section-header d-flex align-items-between text-uppercase">
              <div className="d-flex flex-column flex-shrink-0 store-title">
                {storeLocation.name}{" "}
                <div className="d-flex flex-column justify-content-begin">
                  <a
                    className="text-muted mr-auto view-all-link small store-title"
                    href={`/deals/${storeLocation.slug}`}
                  >
                    View All
                  </a>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-begin w-100 flex-shrink-1">
                <span className="store-distance ml-auto bg-white rounded rounded-lg store-title">
                  {storeLocation.distance.toFixed(2)} mi
                </span>
              </div>
            </h4>
          </div>
          <div className="mt-3 pb-6">
            <HomePageCoupons type={"stores"} homepageId={storeLocation.id} />
          </div>
        </div>
      ))}
    </div>
  );
}
