import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./LocationSearchInput.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faMapMarkerAlt,
  faCompass,
} from "@fortawesome/free-solid-svg-icons";
import { faLocation } from "@fortawesome/pro-solid-svg-icons";
import { updateSessionLocation } from '../lib/api';

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      showCurrentLocationMouse: false,
      showCurrentLocationFocus: true,
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(async (latLng) => {
        await updateSessionLocation({ latitude: latLng.lat, longitude: latLng.lng, location_string: address })
        this.props.setLocation({
          locationName: address,
          longitude: latLng["lng"],
          latitude: latLng["lat"],
        });
        window.location.reload();
      })
      .catch((error) => console.error("Error", error));
    this.props.setShowInputBox(false);
    this.setState({ address: "" });
    if (this.props.setShowSideBar) {
      this.props.setShowSideBar(false);
    }
  };

  handleCurrentLocation = async (e) => {
    this.props.setShowInputBox(false);
    await this.props.setCurrentLocation();
    if (this.props.setShowSideBar) {
      this.props.setShowSideBar(false);
    }
    window.location.reload();
  };

  render() {
    const {
      showCurrentLocationFocus,
      showCurrentLocationMouse,
      address,
    } = this.state;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Choose a location ...",
                className: "location-search-input",
              })}
              onFocus={(e) => {
                this.setState({ showCurrentLocationFocus: true });
              }}
              onBlur={(e) => {
                this.setState({ showCurrentLocationFocus: false });
              }}
            />
            <div
              className="autocomplete-dropdown-container"
              onMouseEnter={(e) => {
                this.setState({ showCurrentLocationMouse: true });
              }}
              onMouseLeave={(e) => {
                this.setState({ showCurrentLocationMouse: false });
              }}
            >
              {(showCurrentLocationMouse ||
                showCurrentLocationFocus ||
                suggestions.length > 0) && (
                <div
                  className="suggestion-item"
                  onClick={this.handleCurrentLocation}
                >
                  <FontAwesomeIcon
                    icon={faLocation}
                    size="sm"
                    className="mr-2"
                  />
                  Current Location
                </div>
              )}
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
