// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs";
// window.Rails = Rails;
// Rails.start();

import {LocationSyncing} from "../src/locationSyncing";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

require("jquery");
require("bootstrap");

require("../packs/analytics");

require("../theme/countdown-timer.min");
require("../theme/slick.min");
require("../theme/jquery-rate-picker");

require("../custom/cs_mapbox");
require("../custom/cs_auto_searches");
require("../theme/main");
require("../theme/custom");
require("../theme/featured_stores");
require("@fortawesome/fontawesome-pro/css/all.css");

// TODO adding this here as temp hack to get build passing
import 'rad_common_js/src/css/main.css.scss';
import appLogo from '../images/app_logo.png';
console.log(appLogo);

import $ from "jquery";
global.$ = jQuery;

import "../stylesheets/application.scss";

import { addressLookup, showRating } from "../theme/custom";

window.addressLookup = addressLookup;
window.showRating = showRating;

LocationSyncing.setup();

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import './app_specific.js';
